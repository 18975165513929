import React from "react"
import SidebarContentList from "./SidebarContentList"
import SidebarContentNav from "./SidebarContentNav"
import SidebarContentTable from "./SidebarContentTable"
import SidebarContentTextarea from "./SidebarContentTextarea"

const getSidebarContentComponent = (sidebarContentItem, sidebarTextColor, isDesktopWidth, i) => {
  let component

  const key = sidebarContentItem.fieldGroupName + "_" + i

  switch (sidebarContentItem.fieldGroupName) {
    case "Post_Postdata_SidebarContent_SidebarContentList":
      component = (
        <SidebarContentList
          list={sidebarContentItem.sidebarList}
          sidebarTextColor={sidebarTextColor}
          isDesktopWidth={isDesktopWidth}
        />
      )
      break

    case "Post_Postdata_SidebarContent_SidebarContentText":
      component = (
        <SidebarContentTextarea
          key={key}
          textarea={sidebarContentItem.sidebarTextarea}
          sidebarTextColor={sidebarTextColor}
          isDesktopWidth={isDesktopWidth}
        />
      )
      break

    case "Post_Postdata_SidebarContent_SidebarContentNav":
      component = (
        <SidebarContentNav
          key={key}
          sidebarContent={sidebarContentItem}
          sidebarTextColor={sidebarTextColor}
          isDesktopWidth={isDesktopWidth}
        />
      )
      break

    case "Post_Postdata_SidebarContent_SidebarContentTable":
      component = (
        <SidebarContentTable
          key={key}
          sidebarTable={sidebarContentItem.sidebarTable}
          sidebarTextColor={sidebarTextColor}
          isDesktopWidth={isDesktopWidth}
        />
      )
      break

    default:
      component = ""
      break
  }
  return component
}

const SidebarContent = (props) => {
  const { sidebar, isDesktopWidth } = props

  return sidebar?.sidebarContent
    ? sidebar.sidebarContent?.map((sidebarContentItem, i) =>
      getSidebarContentComponent(sidebarContentItem, sidebar.postHeaderTextColor, isDesktopWidth, i)
    )
    : ""
}

export default SidebarContent
